<template>
    <header class="content-header " 
      :class="{
         'content-header-home' : pathName == 'home',
         'content-header-home-scrolled': (pathName == 'home' && (isScrolled || isScrolledMobile)),
         'content-header-scrolled': (pathName != 'home' && (isScrolled || isScrolledMobile))
      }">
         <div class="content-header-itens container">
            <div class="content-header-itens-div">
               <router-link class="content-header-itens-logo" to="/">
                  <img class="img-fluid" src="@/assets/img/logos/logo-must.png" alt="logo must" title="logo must" />
               </router-link>
               <router-link class="content-header-itens-logo content-header-itens-logo-home" to="/">
                  <img class="img-fluid" src="@/assets/img/logos/logo-must-dark.png" alt="logo must" title="logo must" />
               </router-link>
               <div class="content-header-itens-links">
                  <div class="content-header-itens-links-div d-none d-lg-flex">
                     <router-link :to="{name: 'my-leads'}" v-content="{section: 'Menu', removeHtml: true}">
                     As suas oportunidades
                     </router-link>
                     <router-link :to="{name: 'my-campaigns'}" v-content="{section: 'Menu', removeHtml: true}">
                     As suas campanhas
                     </router-link>                   
                  </div>
                  <div class="content-header-itens-links-div">
                     <a class="btn-new-lead" @click="createLead()" 
                        v-content="{section: 'Menu', removeHtml: true}">
                     Nova oportunidade
                     </a>
                     <div class="content-border d-none d-lg-flex"></div>
                     <div v-if="Object.keys(user).length" class="content-user d-none d-lg-block">
                        <router-link :to="{name:'my-account'}" class="content-user-name">{{ user.name }}</router-link>
                        <router-link :to="{name:'my-account'}" class="content-user-btn-logoff">Gestão de conta</router-link>
                     </div>
                     <router-link v-else class="btn-new-lead d-none d-lg-flex" :to="{name: 'login'}" 
                        v-content="{section: 'Menu', removeHtml: true}">
                        Login
                     </router-link>                     
                  </div>
                  <MenuMobile />
               </div>
            </div>
         </div>
      </header>
</template>
<script>
import MenuMobile from './MenuMobile.vue';
import { mapActions, mapGetters } from 'vuex'
export default {
   components: {
      MenuMobile
   },
   props: {
      isScrolled: Boolean,
      isScrolledMobile: Boolean,
      pathName: String
   },
   computed: {
      ...mapGetters('User', ['user'])
   },   
   methods: {
      ...mapActions('User', ['logoff']),
      createLead(){
         if(Object.keys(this.user).length == 0){
            this.$notify({
               type: "warn",
               text: "É necessário aceder para registar Leads",
            })
            return false;
         }
         
         this.$root.openModal('modal-leads');
      },
      onLogoff(){
         this.logoff();
         document.location.href = "/";
      }
   }
}
</script>