import Vue from 'vue';
import VueRouter from 'vue-router';
import {protectedPage} from '@/utils';

Vue.use(VueRouter);


const baseRoutes = [
	{
		path: '/',
		name: 'home',
		// beforeEnter(to, from, next){      
	    //   protectedPage(to, from, next);
	    // },
		component: () => import(/* webpackChunkName: "home" */'@/views/Home'),
	},
	{
		path: '/login',
		name: 'login',		
		component: () => import(/* webpackChunkName: "login" */'@/views/Login'),
	},
	{
		path: '/campaigns',
		name: 'campaigns',		
		component: () => import(/* webpackChunkName: "login" */'@/views/Campaigns'),
	},	
	{
		path: '/campaign/:slug',
		name: 'campaign',		
		component: () => import(/* webpackChunkName: "campaign" */'@/views/Campaigns/Detail.vue'),
	},	
	{
		path: '/my-leads',
		name: 'my-leads',
		beforeEnter(to, from, next){      
	      protectedPage(to, from, next);
	    },
		component: () => import(/* webpackChunkName: "my-leads" */'@/views/MyLeads'),
	},
	{
		path: '/my-managers',
		name: 'my-managers',
		beforeEnter(to, from, next){
			protectedPage(to, from, next);
		},
		component: () => import(/* webpackChunkName: "my-managers" */'@/views/MyManagers'),
	},
	{
		path: '/my-campaigns',
		name: 'my-campaigns',
		beforeEnter(to, from, next){      
	      protectedPage(to, from, next);
	    },
		component: () => import(/* webpackChunkName: "my-campaigns" */'@/views/MyCampaigns'),
	},
	{
		path: '/my-account',
		name: 'my-account',
		beforeEnter(to, from, next){      
	      protectedPage(to, from, next);
	    },
		component: () => import(/* webpackChunkName: "my-account" */'@/views/MyAccount'),
	},
	{
		path: '/dev',
		name: 'dev',
		component: () => import(/* webpackChunkName: "404" */'@/views/Dev'),
	},
	{
		path: '*',
		name: '404',
		component: () => import(/* webpackChunkName: "404" */'@/views/404'),
	},
];

const routes = baseRoutes;

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
	    return { x: 0, y: 0 };
	  }
});

export default router;